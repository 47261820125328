import React, { useState } from "react"
import styled from "styled-components"
import "../reset.css"

if (typeof window !== "undefined") {
  // window.location.href = "http://www.mobbycandidatos.com/"

  if(!localStorage.getItem("firstTime")){
    localStorage.setItem("firstTime", true)
  }
}

const Main = styled.main`
  font-family: "Barlow Semi Condensed", sans-serif;
`

export const MainLayout = ({ children }) => {
  return (
    <Main>{children}</Main>
  )
}
